@import "../../global.scss";

.preview {
  // border: 1px solid black;
  margin-top: 2vh;
  min-height: 150px;
  display: grid;

  .leftPrev {
    overflow: hidden;
    width: 100%;
    padding-top: 2%;
    .image {
      width: 100%;
      height: 100%;
    }
  }

  .rightPrev {
    width: 98%;
    padding-left: 2%;

    display: flex;
    flex-direction: column;

    .company {
    }
    .roleinfo {
      width: 98%;
      padding-right: 2%;

      // background-color: orange;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .role {
      }
      .dates {
      }
    }

    .bullets {
      display: block;
      padding-left: 0px;
      .bullet {
        overflow-x: visible; //This was key
      }
    }

    .linkContainer {
      width: 99%;
      padding-right: 1%;
      padding-top: 2%;

      display: flex;
      flex-direction: row;
      height: 25px;
      justify-content: right;
      margin-top: auto;

      .read {
      }
      .link {
        padding: 2px;
      }
      .src {
      }

      a {
        margin-left: 10px;
        display: flex;
        align-items: flex-end;
        cursor: pointer;

        img {
          height: 100%;
        }
      }

      a:hover {
        filter: invert(8%) sepia(100%) saturate(7070%) hue-rotate(247deg) brightness(97%) contrast(143%); //https://codepen.io/sosuke/pen/Pjoqqp
      }
    }
  }
}

.preview.project {
  @include mobile {
    display: flex;
    flex-direction: column;
  }

  grid-template-columns: 1fr 2fr;

  .leftPrev {
    aspect-ratio: 3/2;

    .image {
      object-fit: cover;
    }
  }
}

.preview.blog {
  @include mobile {
    display: flex;
    flex-direction: column;
  }

  grid-template-columns: 1fr 2fr;

  .leftPrev {
    aspect-ratio: 3/2;

    .image {
      object-fit: cover;
    }
  }
}

.preview.experience {
  grid-template-columns: 11vh 1fr;

  .leftPrev {
    padding-top: 10px;
    display: flex;

    .image {
      width: 100%;
      height: auto;
      align-self: flex-start;
    }
  }
  .rightPrev {
    .bullets {
      list-style-type: disc;
      padding-left: 15px;
    }
  }
}
