@import "../../global.scss";

.about {
  margin-top: $topbarHeight;
  padding: 20px;
  scroll-snap-align: start;
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 10vh;

  .left {
    margin-right: 10px;
    width: 23vw;
    max-width: 300px;
    .imageContainer {
      .image {
        height: 70%;
        width: 70%;
        border-radius: 50%;
      }
    }
    .iconContainer {
      margin-top: 1vw;
      display: flex;
      justify-content: center;
    }
  }

  .right {
    width: 68vw;

    .sectionContainer {
      margin-top: 6vw;
      // background-color: blue;
      .lineContainer {
        justify-content: left;
      }
    }

    .previewContainer {
    }
  }

  @include mobile {
    flex-direction: column;
    align-items: center;

    .left {
      margin-right: 0vw;
      width: 50vw;
    }

    .right {
      margin-top: 1vw;
      width: 90vw;
      // background-color: blue;

      .lineContainer {
        width: 100vw;
        justify-content: left;
      }
    }
  }
}
