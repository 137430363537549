@import "../../global.scss";

.nomatch {
  margin-top: $topbarHeight;
  padding: 20px;
  scroll-snap-align: start;
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 10vh;
  background-color: lightcoral;
}
