@import "../../global.scss";

.icon:hover {
  border-color: black;
  text-decoration: none;
}

.icon {
  margin: 5px;

  border: solid 1px white;
  border-radius: 10px;

  height: 40px;
  width: 90%;

  display: flex;
  flex-direction: row;

  color: black;

  // text-decoration: none;
  text-decoration: underline;
  // background-color: palegoldenrod;

  h5 {
    flex: 1 1 auto;
    margin: auto;
    margin-left: 10px;
    margin-right: 10px;
    font-size: large;
    // background-color: palegreen;
  }

  img {
    padding: 1px;
    // background-color: paleturquoise;
  }
}
