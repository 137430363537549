@import "../../global.scss";

.loaderContainer {
  overflow-y: hidden;
  width: 100%;

  .loader {
    transition-property: transform, opacity, width, height;
    transition-duration: 0.7s;
    transition-timing-function: ease-in;
  }

  .in-view {
    transform: none;
    opacity: 1;
  }

  .out-of-view {
    transform: translateY(4px);
    opacity: 0;
  }

  .out-of-view.hLine {
    transform: none;
    width: 10%;
  }

  .in-view.hLine {
    width: 80%;
  }

  .out-of-view.vLine {
    transform: none;
    height: 10%;
  }

  .in-view.vLine {
    height: 80%;
  }
}
