@import "./global.scss";

.app {
  scroll-behavior: smooth;
  scroll-padding-top: $topbarHeight;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
