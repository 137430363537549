@import "../../global.scss";

$topbar-height: 50px;

.topbar {
  width: 100%;
  height: $topbar-height;
  background-color: $mainColor;
  color: $lightColor;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;

  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;

    .name {
      font-size: 30px;
      font-weight: 700;
      text-decoration: none;
      color: inherit;
      margin-right: 40px;
    }
  }

  .menu {
    display: flex;
    flex-direction: row;
    align-items: center;

    ul {
      display: flex;
      float: right;
      list-style: none;
      padding-right: 60px;

      li {
        padding-right: 20px;

        a {
          color: inherit;
          text-decoration: none;
          color: inherit;
          font-size: 20px;
          font-weight: 500;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .hamburger {
    display: none;
  }

  @include mobile {
    .menu {
      width: 200px;
      margin-top: $topbar-height + 20px;
      height: 100vh;
      background-color: $mainColor;
      position: fixed;
      top: 0;
      right: -300px;
      z-index: 2;

      // align-items: left;
      transition: all 1s ease;
      flex-direction: column;
      display: flex;

      ul {
        flex-direction: column;

        li {
          margin-top: 20px;
        }
      }
    }

    .hamburger {
      margin-right: 50px;

      width: 32px;
      height: 25px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;

      span {
        background-color: white;
        width: 100%;
        height: 3px;
        transform-origin: left;
        transition: all 1s ease;
      }
    }

    &.active {
      .menu {
        right: 0;
      }

      .hamburger {
        span {
          &:first-child {
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
