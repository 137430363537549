@import "../../global.scss";

.container {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  padding-bottom: 30px;

  max-width: 700px;

  margin-top: $topbarHeight;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;

  @if $debug {
    background-color: yellow;
  }
}

.header {
  padding-bottom: 20px;
  text-align: center;

  @if $debug {
    background-color: red;
  }

  .title {
    @if $debug {
      background-color: orange;
    }

    text-align: center;
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 2em;
  }

  .description {
    @if $debug {
      background-color: yellow;
    }
    font-style: italic;
    font-size: medium;
  }
  .date {
    @if $debug {
      background-color: palegoldenrod;
    }

    font-style: italic;
    text-align: right;
    padding-right: 15px;
  }

  .thumbnail {
    @if $debug {
      background-color: orange;
    }
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 110%;
  }
}

.markdown {
  @if $debug {
    background-color: blue;
  }

  ul {
    list-style-type: disc;
  }

  li {
    display: list-item;
    overflow-x: visible;
  }

  img {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
